import React, { useEffect } from "react";
import { FormWrapper, ContactFormWrap } from "./style";
import ContactFormScript from "./ContactFormScript";

export default function ContactForm() {

  // function loadFormScript() {
    // if (window.hbspt) {
    //   // @TS-ignore
    //  window.hbspt.forms.create({
    //     region: "na1",
    //     portalId: "6085627",

    //     // Live site Form ID
    //     // formId: "1c44cc23-c344-49d0-a03f-5c88d61e8b3f",

    //     // Clone Form ID
    //     formId: "b34941d8-a546-49e2-8ca3-7926f716ca74",

    //     target: "#contactform",
    //   });
    // }
  // }
  useEffect(() => {
    // const script = document.createElement("script");
    // script.src = "//js.hsforms.net/forms/v2.js";
    // script.type = "text/javascript";
    // script.charset = "utf-8";
    // // script.async = true;
    // document.body.appendChild(script);
    // script.addEventListener("load", loadFormScript);
    setTimeout(()=>{

      ContactFormScript()
    } ,1000)
  }, []);

  return (
    <FormWrapper className="contact-form-wrap">
      <ContactFormWrap id="contactform"></ContactFormWrap>
    </FormWrapper>
  );
}
