import styled, { device, themeGet } from "@styled";

export const FormWrapper = styled.section`
  position: relative;
  z-index: 1;
  input,
  select,
  textarea {
    height: 60px;
    padding-left: 20px;
    width: 100%;
    border-radius: 2px;
    border: 1px solid ${themeGet("colors.secondaryDark")};
  }
  textarea {
    min-height: 130px;
  }
  label {
    color: #fff;
    font-size: 16px;
    text-transform: uppercase;
    margin-bottom: 14px;
    line-height: 1;
  }
`;

export const ContactFormWrap = styled.div`
  form {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 25px;
    ${device.medium} {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 25px;
    }
    .hs_company,
    .hs_message,
    .hs_upload,
    .legal-consent-container,
    .hs_submit {
      ${device.medium} {
        grid-column: span 2;
      }
    }
    .hs-fieldtype-checkbox {
      .inputs-list {
        display: flex;
        align-items: center;
        margin: 0;
        padding: 0;
        .hs-form-checkbox {
          &:not(:first-child) {
            margin-left: 25px;
          }
          label {
            display: flex;
            align-items: center;
            margin: 0;
            input {
              min-width: 30px;
              margin: 0 10px 0px 0;
            }
          }
        }
      }
    }
    .hs-error-msg {
      color: ${themeGet("colors.primary")};
      text-transform: none;
      margin-top: 10px;
    }
    .hs-field-desc {
      color: #fff;
      font-size: 16px;
      margin: 0;
    }
    .hs_error_rollup {
      ${device.medium} {
        grid-column: span 2;
      }
      label {
        color: #f94f19;
        text-transform: none;
        margin-top: 10px;
      }
    }
    .hs_upload {
      display: flex;
      align-items: center;
      flex-direction: column;
      color: #fff;
      ${device.small} {
        flex-direction: row;
      }
      label {
        ${device.small} {
          margin: 0;
        }
      }
      input {
        height: auto;
        padding: 0;
        border: none;
        ::-webkit-file-upload-button {
          background: #5b29b2;
          color: #fff;
          border: navajowhite;
          padding: 15px 30px;
          border-radius: 7px;
          margin: 3px 15px;
          cursor: pointer;
          transition: ${themeGet("transition")};
          &:hover {
            background: #4b1c9c;
            transform: translateY(-3px);
          }
        }
      }
    }
    .legal-consent-container {
      p {
        font-size: 16px;
        width: 100%;
        max-width: initial;
        color: #dbb5f6;
        text-align: center;
      }
    }
    .hs_submit {
      text-align: center;
      .hs-button {
        white-space: nowrap;
        cursor: pointer;
        border-radius: 15px;
        border-width: 1px;
        font-size: 18px;
        font-weight: 500;
        line-height: 50px;
        height: 50px;
        padding: 0 30px;
        background-color: #f94f19;
        border-color: #f94f19;
        color: #fff;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: auto;
        min-width: 200px;
        transition: ${themeGet("transition")};
        &:hover {
          transform: translateY(-3px);
        }
      }
    }
  }
`;
