import * as React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-01";
import Footer from "@layout/footer/layout-01";
import PageHeader from "@containers/page-header/layout-01";
import QuoteForm from "@containers/translation-quote-form";
import IntroAreaOne from "@components/introarea/layout-4";
import IntroArea from "@components/introarea/layout-6";
import KeyBenefits from '@containers/ai-voiceover/key-benifits';
import LocalizeGrid from "@components/localize-grid/layout-4";
import CaseStudy from "@containers/global/case-study";
import { caseStudiesData } from "@constants";
import ContactArea from "@containers/contact-us";
const AudioDescriptionSolutionsPage = ({ location, data }) => {
  const content = normalizedData(data?.page.content || []);
  const globalContent = normalizedData(data?.allGeneral.nodes || []);

  return (
    <Layout location={location}>
      <Seo
        title="Audio Description Solutions"
        description="Empower your audience with Andovar’s expert audio description services—where accessibility meets excellence. Let Andovar make your media content inclusive and engaging for all."
        />
      <Header
        data={{
          ...globalContent["menu"],
          ...data.site.siteMetadata,
        }}
      />
      <main className="site-wrapper-reveal">
        <PageHeader data={content["audio-description-solutions-page-header"]} />
        <QuoteForm />
        <IntroAreaOne layout={6} data={content["audio-description-solutions-intro-data"]} />
        <IntroArea layout={3} data={content["audio-description-solutions-body"]} />
        <KeyBenefits data={content['audio-description-solutions-data']} />
        <LocalizeGrid layout={2}  data={content["why-choose-andovar-data"]} />
        <CaseStudy layout={3} data={caseStudiesData} />
        <ContactArea Layout={2} />
      </main>
      <Footer data={{ ...data.site.siteMetadata }} />
    </Layout>
  );
};

export const query = graphql`
  query AudioDescriptionSolutionsPageQuery {
    allGeneral {
      nodes {
        section
        ...HeaderOne
      }
    }
    site {
      ...Site
    }
    page(title: { eq: "audio-description-solutions" }, pageType: { eq: "innerpage" }) {
      content {
        ...PageContent
      }
    }
  }
`;

AudioDescriptionSolutionsPage.propTypes = {
  location: PropTypes.shape({}),
  data: PropTypes.shape({
    allGeneral: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        contact: PropTypes.shape({}),
      }),
    }),
    page: PropTypes.shape({
      content: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
};

export default AudioDescriptionSolutionsPage;
