import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "@ui/wrapper";
import Text from "@ui/text";
import Heading from "@ui/heading";
import Anchor from "@ui/anchor";
import { HeadingType, TextType, ButtonType } from "@utils/types";
import ContactForm from "@components/contactform";
import {
  ConatactWrapper,
  ConatactWrapperOuter,
  ConatactWrapInner,
  ConatactWrapHead,
  TagLine,
} from "./style";

const ContactArea = ({ layout, ...props }) => {
  return (
    <ConatactWrapper layout={layout} {...props} id="contats-us">
      <Container>
        <ConatactWrapperOuter>
          <TagLine>
            Grow your wor<Text as="span">l</Text>d.
          </TagLine>
          <Row alignItems="center">
            <Col lg={12}>
              <ConatactWrapInner>
                <ConatactWrapHead>
                  <Heading as="h1">Talk to an expert</Heading>
                  <Text>
                    Please provide a little bit of information in the form below
                    and we will be in touch soon. If you are interested in
                    working for Andovar (as translator/ SLV or full-time
                    employee), please visit{" "}
                    <Anchor path="https://careers.andovar.com/">
                      our careers
                    </Anchor>{" "}
                    site.
                  </Text>
                </ConatactWrapHead>
                <ContactForm />
              </ConatactWrapInner>
            </Col>
          </Row>
        </ConatactWrapperOuter>
      </Container>
    </ConatactWrapper>
  );
};

ContactArea.propTypes = {
  data: PropTypes.shape({
    headings: PropTypes.arrayOf(PropTypes.shape(HeadingType)),
    texts: PropTypes.arrayOf(PropTypes.shape(TextType)),
    buttons: PropTypes.arrayOf(PropTypes.shape(ButtonType)),
    layout: PropTypes.oneOf([1, 2, 3]),
  }),
};
ContactArea.defaultProps = {
  layout: 1,
};

export default ContactArea;
