export default function ContactFormScript() {
  const script = document.createElement("script");
    script.src = "//js.hsforms.net/forms/v2.js";
    script.type = "text/javascript";
    script.charset = "utf-8";
  script.defer = true;
  script.async = true;
  script.loading = "lazy";
  document.body.appendChild(script);
    script.addEventListener("load", ()=>{
      if (window.hbspt) {
       window.hbspt.forms.create({
          region: "na1",
          portalId: "6085627",
          formId: "b34941d8-a546-49e2-8ca3-7926f716ca74",

          target: "#contactform",
        });
      }
    });

}