import styled, { css, device } from "@styled"; 

export const IntroWrapper = styled.section`
  padding: 60px 0 100px;
  ${device.medium} {
    padding: 100px 0 100px;
  }
  ${(props) =>
        props.layout === 1 &&
        css`
      background: #fff;
    `}
  ${(props) =>
        props.layout === 2 &&
        css`
      background: #f8f8f8;
    `}
  ${(props) =>
    props.layout === 3 &&
    css`
    padding:60px 0;
    ${device.medium} {
      padding:100px 0;
    }
    .col-lg-6{
      h3{
        font-size:20px;
        margin:0 0 15px;
        ${device.medium} {
          font-size:22px;
        }
        ${device.xlarge} {
          font-size:24px;
        }
      }
      p{
        font-size:18px;
        ${device.xlarge} {
          font-size:20px;
        }
        ${device.xxxlarge} {
          font-size:22px;
        }
      }
    }
  `}
  ${(props) =>
    props.layout === 4 &&
    css`
    padding:60px 0;
    background-color: #f8f8f8;
    ${device.medium} {
      padding:100px 0;
    }
    .col-lg-6{
      h3{
        font-size:20px;
        margin:0 0 15px;
        ${device.medium} {
          font-size:22px;
        }
        ${device.xlarge} {
          font-size:24px;
        }
      }
      p{
        font-size:18px;
        ${device.xlarge} {
          font-size:20px;
        }
        ${device.xxxlarge} {
          font-size:22px;
        }
      }
    }
  `}
  ${(props) =>
    props.layout === 5 &&
    css`
    padding:0 0 60px 0;
    ${device.medium} {
      padding:0 0 100px 0;
    }
    .col-lg-6{
      h3{
        font-size:20px;
        margin:0 0 15px;
        ${device.medium} {
          font-size:22px;
        }
        ${device.xlarge} {
          font-size:24px;
        }
      }
      p{
        font-size:18px;
        ${device.xlarge} {
          font-size:20px;
        }
        ${device.xxxlarge} {
          font-size:22px;
        }
      }
    }
  `}
`;

export const LeftBox = styled.div`
  @media ${device.medium} {
    margin-bottom: 20px;
  }
  .btn-learn-more {
    margin: 25px 0 0;
  }
`;
export const RightBox = styled.div`
display:flex;
justify-content:end;
  margin: 15px 0;
  ${device.large} {
    margin: 0;
  }
`;
export const ButtonWrap = styled.div`
  margin-top: 30px;
  a {
    &:first-child {
      margin-left: 0;
    }
  }
`;
export const ContentWarp = styled.div`
  margin-top: 40px;
  h3 {
    margin-top: 30px;
    margin-bottom: 10px;
  }
  p {
    font-size: 17px;
  }
`;
export const Styledtitle = styled.h3`
  font-size: 22px;
  font-weight: 700;
`;